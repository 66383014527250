import { Button, ButtonVariant } from "brass-ui-kit";
import { APP_SIGNUP_URL } from "data/config";
import React from "react";
import cx from "classnames";

import * as styles from "./OpenAccountButton.module.scss";

interface OpenAccountButtonProps {
  label?: string;
  as?: "a" | "button";
  onClick?: () => void;
  link?: string;
  className?: string;
}

const OpenAccountButton: React.FC<OpenAccountButtonProps> = ({
  label,
  as = "a",
  onClick,
  link,
  className,
}) => {
  return (
    <Button
      as={as}
      target={as === "a" ? "_blank" : undefined}
      href={as === "a" ? link : undefined}
      variant={ButtonVariant.Primary}
      className={className ? className : styles.OpenAccountButton}
      onClick={onClick}
    >
      {label || (
        <>
          Open an account <span>in 10 minutes</span>
        </>
      )}
    </Button>
  );
};

OpenAccountButton.defaultProps = {
  label: "Open an account in 10 minutes",
  as: "a",
  link: APP_SIGNUP_URL,
};

export default OpenAccountButton;
