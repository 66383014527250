import {
  aboutPageUrl,
  // brassCapitalTermsPageUrl,
  capitalPageUrl,
  customersPageUrl,
  internetCompaniesPageUrl,
  pressPageUrl,
  privacyPolicyPageUrl,
  retailPageUrl,
  termsOfServicePageUrl,
  whyBrassPageUrl,
  helpPageUrl,
  careersPageUrl,
  blogPageUrl,
  guidesPageUrl,
  // launchpadPageUrl,
  // payrollPageUrl,
  cardsTermsAndConditionsPageUrl,
  savingsPageUrl,
} from "./../../../data/pageUrl";

import appData from "../../../../app.json";
import {
  BBC_WEBSITE_URL,
  CONTACTS_INFO_EMAILS,
  SUPPORT_EMAIL,
  SUPPORT_NUMBER,
} from "data/config";

export const FOOTER_CONFIG = {
  sections: [
    {
      label: "Industry",
      subItems: [
        {
          label: "Retail businesses",
          route: retailPageUrl,
        },
        {
          label: "Internet companies",
          route: internetCompaniesPageUrl,
        },
      ],
    },
    // {
    //   label: "Products",
    //   subItems: [
    //     {
    //       label: "Brass Capital",
    //       route: capitalPageUrl,
    //     },
    //     {
    //       label: "Payroll",
    //       route: payrollPageUrl,
    //     },
    //     // {
    //     //   label: "Launchpad",
    //     //   route: launchpadPageUrl,
    //     // },

    //     // {
    //     //   label:'Developers',
    //     //   route:industyPageUrl,
    //     // }
    //   ],
    // },
    {
      label: "About",
      subItems: [
        {
          label: "Customers",
          route: customersPageUrl,
        },
        {
          label: "Company",
          route: aboutPageUrl,
        },
        {
          label: "Why Brass",
          route: whyBrassPageUrl,
        },
        {
          label: "Press resources",
          route: pressPageUrl,
        },
        {
          label: "Careers",
          route: careersPageUrl,
        },
      ],
    },
    {
      label: "Support",
      subItems: [
        {
          label: "Blog",
          route: blogPageUrl,
        },
        {
          label: "Guides",
          route: guidesPageUrl,
        },
        {
          label: "Help",
          route: helpPageUrl,
        },
        {
          label: "BBC",
          route: BBC_WEBSITE_URL,
          isExternal: true,
        },
      ],
    },
    {
      label: "Legal",
      subItems: [
        {
          label: "Terms of service",
          route: termsOfServicePageUrl,
        },
        {
          label: "Privacy policy",
          route: privacyPolicyPageUrl,
        },
        // {
        //   label: "Capital TOS",
        //   route: brassCapitalTermsPageUrl,
        // },
        {
          label: "Cards T&Cs",
          route: cardsTermsAndConditionsPageUrl,
        },
      ],
    },
    {
      label: "Follow",
      subItems: [
        {
          label: "Twitter",
          route: appData.social.twitter,
          isExternal: true,
        },
        {
          label: "LinkedIn",
          route: appData.social.linkedin,
          isExternal: true,
        },
        {
          label: "Instagram",
          route: appData.social.instagram,
          isExternal: true,
        },
        {
          label: "Youtube",
          route: appData.social.youtube,
          isExternal: true,
        },
      ],
    },
    {
      label: "Contact",
      subItems: [
        {
          label: SUPPORT_NUMBER,
          route: `tel:${SUPPORT_NUMBER}`,
          isExternal: true,
        },
        {
          label: "sales@trybrass.com",
          route: CONTACTS_INFO_EMAILS.sales,
          isExternal: true,
        },
        {
          label: SUPPORT_EMAIL,
          route: CONTACTS_INFO_EMAILS.help,
          isExternal: true,
        },
        {
          label: "business@trybrass.com",
          route: CONTACTS_INFO_EMAILS.business,
          isExternal: true,
        },
      ],
    },
  ],
};
