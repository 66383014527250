import React from "react";
import { document, window } from "browser-monads";

const BRASS_APP_SIGNUP_URL = `${process.env.GATSBY_APP_URL}/signup`;

const AD_SOURCES = {
  gclid: "Google",
  fbclid: "Facebook/Instagram",
  utm_source: undefined,
};

const addReferrerToSignUpLinks = (links) => {
  let sourceFromStorage;
  Object.keys(AD_SOURCES).forEach((key) => {
    const sourceValue = window.localStorage.getItem(key);
    if (sourceValue) sourceFromStorage = sourceValue;
  });
  if (links) {
    links.forEach((el) => {
      if (sourceFromStorage) {
        el.setAttribute(
          "href",
          `${BRASS_APP_SIGNUP_URL}?referrer=${sourceFromStorage}`
        );
      }
    });
  }
};

const addClickEventListenerToSignUpLinks = (links) => {
  if (links) {
    links.forEach((el) => {
      el.addEventListener("click", () => {
        window.gtag &&
          window.gtag("event", "conversion", {
            send_to: "AW-340304064/475DCOzq7pEDEMDBoqIB",
          });
      });
    });
  }
};

export const mdxComponent = {
  a: (props) => (
    <a target="_blank" rel="nofollow noreferrer" {...props}>
      {props.children}
    </a>
  ),
};

export const signupTrackingAndAnalytics = () => {
  const brassAppSignupLinks = document.querySelectorAll(
    `a[href^="${BRASS_APP_SIGNUP_URL}"]`
  );
  addReferrerToSignUpLinks(brassAppSignupLinks);
  addClickEventListenerToSignUpLinks(brassAppSignupLinks);
};

export const handleAdSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  Object.keys(AD_SOURCES).forEach((key) => {
    const utmSourceKey = "utm_source";
    const sourceFromParams = searchParams.get(key);
    const sourceFromStorage = window.localStorage.getItem(key);

    if (!sourceFromStorage && sourceFromParams) {
      window.localStorage.setItem(
        key,
        key === utmSourceKey ? sourceFromParams : AD_SOURCES[key]
      );
    }

    if ((sourceFromStorage && !sourceFromParams) || !window.location.search) {
      window.localStorage.removeItem(key);
    }
  });
};
