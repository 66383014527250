import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import * as styles from "./Hero.module.scss";

const HeroTitle = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const wordRef = useRef<HTMLSpanElement>(null);
  const words = ['faster', 'easier', 'effortlessly'];
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWord((prev) => (prev + 1) % words.length);
    }, 2000);
    
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (wordRef.current) {
      gsap.fromTo(wordRef.current,
        {
          opacity: 0,
          y: 10
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.4,
          ease: "power2.out"
        }
      );
    }
  }, [currentWord]);

  return (
    <h1 className={styles.Hero_header_text}>
      Open an account{' '}
      <div className={styles.Hero_header_animated}>
        <span ref={wordRef}>
          {words[currentWord]}
        </span>
      </div>
    </h1>
  );
};

export default HeroTitle;