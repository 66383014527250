export const APP_URL = process.env.GATSBY_APP_URL;
export const API_URL = process.env.GATSBY_API_URL;
export const API_KEY = process.env.GATSBY_API_KEY;
export const LAUNCHPAD_API_URL = process.env.GATSBY_LAUNCHPAD_API_URL;
export const LAUNCHPAD_API_KEY = process.env.GATSBY_LAUNCHPAD_API_KEY;
export const AIRTABLE_API_KEY = process.env.GATSBY_AIRTABLE_API_KEY;
export const AIRTABLE_BASE_KEY = process.env.GATSBY_AIRTABLE_BASE_KEY;
export const AIRTABLE_BASE_NAME = process.env.GATSBY_AIRTABLE_BASE_NAME;
export const BBC_WEBSITE_URL = process.env.GATSBY_BBC_WEBSITE_URL;
export const LAUNCHPAD_APP_URL = process.env.GATSBY_LAUNCHPAD_APP_URL;
export const BRASS_MONEY_URL = process.env.GATSBY_BRASS_MONEY_APP_URL;
export const TWITTER_URL = process.env.GATSBY_TWITTER_URL;
export const TWITTER_APP_ID = process.env.GATSBY_TWITTER_APP_ID;
export const BRASS_HOMEPAGE_URL = process.env.GATSBY_BRASS_HOMEPAGE_URL;
export const KENYA_HOMEPAGE_URL = process.env.GATSBY_KENYA_HOMEPAGE_URL;

export const APP_SIGNUP_URL = `${APP_URL}/signup`;
export const APP_LOGIN_URL = `${APP_URL}/login`;

export const LAUNCHPAD_LOGIN_URL = `${LAUNCHPAD_APP_URL}/auth/login`;

export const BRASS_WALL_OF_LOVE = `${TWITTER_URL}/timelines/${TWITTER_APP_ID}`;
export const BRASS_API_DOCS_URL = "https://docs.trybrass.com";
export const BRASS_PRESS_KIT_URL =
  "https://drive.google.com/drive/folders/1hxKgfz1-VIblH50lI1El73k_-puucknd";
export const BRASS_APPOINTMENT_BOOKING_URL =
  "https://form.typeform.com/to/lOl2qq";

export const HONESTY_URL =
  "https://medium.com/age-of-awareness/how-to-be-intellectually-honest-2874d57ff05c";

export const BRASS_TALENT_NETWORK_FORM =
  "https://docs.google.com/forms/d/e/1FAIpQLSeeOng4Y2yilHxaLiIc0bC3tiPcHL5EYGbL9WiU4gyLmC96Xw/viewform";

export const BRASS_PRESS_URL = "/press";
export const BRASS_SUPPORT_URL = "/support";
export const BRASS_WHY_URL = "/why-brass";
export const BRASS_BUSINESS_SUPPORT_URL =
  "https://form.typeform.com/to/yPpMgbFa";

export const NOREBASE_URL = "https://www.norebase.com";

export const LUNCHPAD_APP_SIGN_UP_URL = `${LAUNCHPAD_APP_URL}/auth/signup`;

export const AMPLITUDE_API_KEY = process.env.GATSBY_APP_AMPLITUDE_API_KEY;

export const CMS_API_URL = process.env.GATSBY_CMS_API_URL;

export const EBOOK_ASSET_URL =
  "https://static.getbrass.co/assets/others/Brass_Ebook.pdf";

export const VIDEO_SOURCES = {
  youtube: "https://www.youtube.com/embed",
  vimeo: "https://player.vimeo.com/video",
};

export const CONTACTS_INFO_EMAILS = {
  help: "mailto:help@trybrass.com",
  sales: "mailto:sales@trybrass.com",
  business: "mailto:business@trybrass.com",
};

export const META_CARD_BASE_PATH =
  "https://static.trybrass.com/website-assets/img/preview-images";

export const BUSINESS_APP_GOOGLE_PLAY_STORE_URL =
  process.env.GATSBY_BUSINESS_APP_GOOGLE_PLAY_STORE_URL;

export const STARTER_APP_GOOGLE_PLAY_STORE_URL =
  process.env.GATSBY_STARTER_APP_GOOGLE_PLAY_STORE_URL;

export const BUSINESS_APP_IOS_APP_STORE_URL =
  process.env.GATSBY_BUSINESS_APP_IOS_APP_STORE_URL;

export const STARTER_APP_IOS_APP_STORE_URL =
  process.env.GATSBY_STARTER_APP_IOS_APP_STORE_URL;

export const ANDROID_URL_SCHEME = process.env.GATSBY_ANDROID_URL_SCHEME;

export const IOS_URL_SCHEME = process.env.GATSBY_IOS_URL_SCHEME;

export const STARTER_APP_ANDROID_URL_SCHEME =
  process.env.GATSBY_STARTER_APP_ANDROID_URL_SCHEME;

export const STARTER_APP_IOS_URL_SCHEME =
  process.env.GATSBY_STARTER_APP_IOS_URL_SCHEME;

export const SUPPORT_NUMBER = process.env.GATSBY_SUPPORT_NUMBER;
export const SUPPORT_EMAIL = process.env.GATSBY_SUPPORT_EMAIL;
