import React, { useState } from "react";
import { Link } from "gatsby";
import cx from "classnames";

import { NAVBAR_CONFIG } from "../config";
import { IconFooterCaret } from "assets/media/svgs";
import { RenderNavLinkProps } from "../Navbar";
import { uniqueId } from "lodash";
import { clean, detectMobileDevice } from "helpers/utils/generics";
// import { CountrySwitcher } from "../CountrySwitcher";

import * as styles from "./MobileMenu.module.scss";
import { LAUNCHPAD_LOGIN_URL } from "data/config";
import { AuthActionType } from "types";
import { LayoutContext } from "context";

interface MobileMenuProps {
  open: boolean;
  isLaunchpad: boolean;
  toggleMobileMenu: () => void;
  signUpLink: string;
  loginLink: string;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  open,
  toggleMobileMenu,
  isLaunchpad,
  signUpLink,
  loginLink,
}) => {
  const [activeSubSection, setActiveSubSection] = useState("");

  const { updateContext } = React.useContext(LayoutContext);

  const isUsingMobileOS = detectMobileDevice();

  const handleDownloadAction = (
    authActionModalType: AuthActionType = AuthActionType.Login
  ) => {
    toggleMobileMenu();
    updateContext({
      openAuthActionModal: true,
      authActionModalType,
    });
  };

  const renderLink = (config: Omit<RenderNavLinkProps, "subItems">) => {
    const Component = config.isExternalUrl ? "a" : (Link as any);

    const componentProps = {
      activeClassName: config.isExternalUrl
        ? undefined
        : styles.MobileMenu_nav_item__active,
      className: config.className || styles.MobileMenu_nav_item,
      to: config.isExternalUrl ? undefined : config.route,
      href: config.isExternalUrl ? config.route : undefined,
      rel: config.isExternalUrl ? "nofollow noreferrer" : undefined,
      onClick: () => {
        toggleMobileMenu();
      },
      target: config.isExternalUrl ? "_blank" : undefined,
    };

    if (config.partiallyActive)
      componentProps["partiallyActive"] = config.partiallyActive;

    return <Component {...clean(componentProps)}>{config.label}</Component>;
  };

  const renderNavLink = ({
    label,
    route,
    subItems,
    isExternalUrl,
  }: RenderNavLinkProps) => {
    if (subItems) {
      return (
        <div className={styles.MobileMenu_nav_item_withDropdown}>
          <div
            onClick={() => {
              if (activeSubSection === label) {
                return setActiveSubSection("");
              }
              setActiveSubSection(label as string);
            }}
            key={route}
            className={cx(styles.MobileMenu_nav_item_withDropdown_link)}
          >
            <span>{label}</span>
            <IconFooterCaret
              className={cx(
                styles.MobileMenu_nav_item_withDropdown_link_caret,
                {
                  [styles.MobileMenu_nav_item_withDropdown_link_caret__active]:
                    label === activeSubSection,
                }
              )}
            />
          </div>
          <div
            className={cx(styles.MobileMenu_nav_item_withDropdown_subMenu, {
              [styles.MobileMenu_nav_item_withDropdown_subMenu__active]:
                activeSubSection === label,
            })}
          >
            {subItems.map(({ label, route, isExternalUrl }) => {
              return (
                <React.Fragment key={route}>
                  {renderLink({
                    className:
                      styles.MobileMenu_nav_item_withDropdown_subMenu_item,
                    route: route || "/",
                    partiallyActive: !isExternalUrl,
                    label: <h6>{label}</h6>,
                    isExternalUrl,
                  })}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      );
    }
    return renderLink({ label, isExternalUrl, route });
  };

  return (
    <div
      className={cx(styles.MobileMenu, {
        [styles.MobileMenu__open]: open,
      })}
    >
      <nav className={styles.MobileMenu_nav}>
        {[...NAVBAR_CONFIG.left, ...NAVBAR_CONFIG.right].map((config) => {
          return (
            <React.Fragment key={uniqueId("nav-link-left")}>
              {renderNavLink(config as Required<typeof config>)}
            </React.Fragment>
          );
        })}
        <a
          className={styles.MobileMenu_nav_item}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (isLaunchpad || !isUsingMobileOS)
              window.open(loginLink, "_blank");
            else handleDownloadAction();
          }}
          rel="noopener noreferrer"
        >
          Login
        </a>
        <a
          className={styles.MobileMenu_nav_item}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (isLaunchpad || !isUsingMobileOS)
              window.open(signUpLink, "_blank");
            else handleDownloadAction(AuthActionType.SignUp);
          }}
          rel="noopener noreferrer"
        >
          Open an account
        </a>
      </nav>
    </div>
  );
};

export default MobileMenu;
